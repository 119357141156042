import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QApiReferenceService } from 'src/app/services/q-api-reference.service';
import { QHttpHelperService } from 'src/app/services/q-http-helper.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeskServService {

  constructor(public httpC: HttpClient, private apiReference: QApiReferenceService,
              private http: QHttpHelperService) { }

getAllDesks(storeIdList): Observable<any> {
             
            const params = {storeCodes: storeIdList} ;
            // new HttpParams().set('storeCodes', storeIdList);

            const req = this.http.Post(environment.baseUrl + this.apiReference.baseURLForGetDesksByStoreCodeList , params);

            return req.pipe(response => {
            //  console.log('res' + JSON.stringify(response));
             return response; });
                   }
                 
getAvailableAllDesks(storeId , empId): Observable<any> {
                  // const OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
              // console.log('sessionsId: ' + storeId );

              if (storeId != null ) {

              const params = new HttpParams().set('storeCode', storeId).set('empId', empId);

              const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetAvailableAllDesks , params);

              return req.pipe(response => {
              //  console.log('res' + JSON.stringify(response));
               return response; });
                     }
                   }



                 //
   addDesk(empId, deskNumber, storeId): Observable<any> {

              if (empId != null && deskNumber != null && storeId != null) {

              const params = new HttpParams().set('empId', empId)
              .set('deskNumber', deskNumber).set('storeCode', storeId);

              const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForAddDesk , params);

              return req.pipe(response => {
              //  console.log('res' + JSON.stringify(response));
               return response; });
                     }
                   }

                   getAllDesksAndSessions(storeCode): Observable<any> {

                    if (storeCode != null) {
                    const params = { storeCode : storeCode }  ;
                  const req = this.http.Post(environment.baseUrl + this.apiReference.baseURLForGetDeskAndSessionsByStoreCode , params);
      
                    return req.pipe(response => {

                     return response; });
                           }
                         }

  editDesk(empId, id, deskNumber): Observable<any> {

    if (empId != null && id != null && deskNumber != null) {

                    const params = new HttpParams().set('empId', empId)
                    .set('id', id).set('deskNumber', deskNumber);

                    const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForEditDesk , params);

                    return req.pipe(response => {
                    //  console.log('res' + JSON.stringify(response));
                     return response; });
                           }
      }

  deleteDesk(empId, id ): Observable<any> {

        if (empId != null && id != null ) {

                        const params = new HttpParams().set('empId', empId)
                        .set('id', id);

                        const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForDeleteDesk , params);

                        return req.pipe(response => {
                        //  console.log('res' + JSON.stringify(response));
                         return response; });
                               }
                             }

   activeDesk(empId, id, active): Observable<any> {
       if (empId != null && id != null && active != null) {

     const params = new HttpParams().set('empId', empId)
     .set('id', id).set('active', active);

     const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForActiveDesk , params);

     return req.pipe(response => {
    //  console.log('res' + JSON.stringify(response));
     return response; });
                                                     }
                                }

 searchDesk(desk_num, store_code , is_active): Observable<any> {

            if (is_active != null ) {

            const params = new HttpParams().set('desk_num', desk_num).set('store_code', store_code)
            .set('is_active', is_active);

            const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForSearchDesk , params);

            return req.pipe(response => {
            //  console.log('res' + JSON.stringify(response));
             return response; });
                   }
                 }



}
