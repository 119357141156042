import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QApiReferenceService } from 'src/app/services/q-api-reference.service';
import { QHttpHelperService } from 'src/app/services/q-http-helper.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesServService {

  constructor(public httpC: HttpClient, private apiReference: QApiReferenceService,
    private http: QHttpHelperService) { }

  getAllStoreServices(storeIdsList): Observable<any> {

    if (storeIdsList != null) {
      const parameters = { storeIds: storeIdsList };
      // const params = new HttpParams().set('storeIds', storeIdsList);
      const req = this.http.Post(environment.baseUrl + this.apiReference.baseURLForStoreServices,
        parameters);

      return req.pipe(response => {
        return response;
      });
    }


  }

  getAllStoresServicesList(): Observable<any> {
    const params = null;

    const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetAllStoresServices,
      params);

    return req.pipe(response => {
      return response;
    });


  }


  activeService(empId, serviceId, active): Observable<any> {
    if (empId != null && serviceId != null && active != null) {
      const params = new HttpParams().set('empId', empId)
        .set('serviceId', serviceId).set('active', active);

      const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForActiveService, params);

      return req.pipe(response => {
        // console.log('res' + JSON.stringify(response));
        return response;
      });
    }
  }

  enableDisableStoreService(empId, storeId, serviceId, active): Observable<any> {
    if (empId != null && serviceId != null && active != null) {
      const params = new HttpParams().set('empId', empId).set('storeId', storeId)
        .set('serviceId', serviceId).set('active', active);

      const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForEnableDisableStoreService, params);

      return req.pipe(response => {
        // console.log('res' + JSON.stringify(response));
        return response;
      });
    }
  }

  SearchService(serviceName, storeId, active): Observable<any> {
    if (serviceName != null && storeId != null && active != null) {
      const params = new HttpParams().set('serviceName', serviceName).set('storeId', storeId).set('active', active);

      const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForSearchService, params);

      return req.pipe(response => {
        // console.log('res' + JSON.stringify(response));
        return response;
      });
    }
  }

  AddService(empId, serviceNameAr, serviceName, serviceLetter,
    avgServingTime, manualServingTime): Observable<any> {

    if (empId != null && serviceName != null) {

      const params = new HttpParams().set('empId', empId).set('serviceNameAr', serviceNameAr)
        .set('serviceName', serviceName).set('serviceLetter', serviceLetter).
        set('avgServingTime', avgServingTime).set('manualServingTime', manualServingTime);


      const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForAddService, params);

      return req.pipe(response => {
        return response;
      });
    }
  }

  getServiceById(id): Observable<any> {

    if (id != null) {

      const params = new HttpParams().set('id', id);


      const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetService, params);

      return req.pipe(response => {
        return response;
      });
    }
  }

  getServiceNAmes(): Observable<any> {
    const params = null;
    const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetServiceNames, params);

    return req.pipe(response => {
      return response;
    });
  }
  editService(id, empId, serviceNameAr, serviceName, serviceLetter,
    avgServingTime, manualServingTime): Observable<any> {

    if (empId != null && serviceName != null) {

      const params = new HttpParams().set('id', id).set('empId', empId).set('serviceNameAr', serviceNameAr)
        .set('serviceName', serviceName).set('serviceLetter', serviceLetter).
        set('avgServingTime', avgServingTime).set('manualServingTime', manualServingTime);


      const req = this.http.Get(environment.baseUrl + this.apiReference.baseURLForEditService, params);

      return req.pipe(response => {
        return response;
      });
    }
  }

  getDeviceServices(deviceId, deviceSecret): Observable<any> {
    if (deviceId != null && deviceSecret != null) {
      const params = new HttpParams().set('deviceId', deviceId);
      const req = this.http.deviceGet(environment.baseUrl + this.apiReference.baseURLForGetDeviceServices,
        deviceId, deviceSecret, params);


      return req.pipe(response => {
        return response;
      });
    }

  }

  activateDeviceService(empId, serviceId, deviceId, isActive): Observable<any> {
    if (empId != null && serviceId != null && deviceId != null && isActive != null) {
      const parameters = { empId: empId, serviceId: serviceId, deviceId: deviceId, isActive: isActive };
      // const params = new HttpParams().set('storeIds', storeIdsList);
      const req = this.http.Post(environment.baseUrl + this.apiReference.baseURLForActivateDeviceServices,
        parameters);

      return req.pipe(response => {
        return response;
      });
    }
  }

  getStoreAndUserServices(userId, storeId): Observable<any> {
    if (userId != null && storeId != null) {
      const parameters = { storeId: storeId, userId: userId};
      const req = this.http.Post(environment.baseUrl + this.apiReference.baseURLForGetStoreServicesAndUserService,
        parameters);

      return req.pipe(response => {
        return response;
      });
    }
  }

  assignServiceToUser(empId, userId, storeId, servicesId): Observable<any> {
    if (empId != null && userId != null && storeId != null && servicesId != null) {
      const parameters = { empId:[empId], storeId:[storeId], userId: [userId], servicesId: servicesId};
      const req = this.http.Post(environment.baseUrl + this.apiReference.baseURLForAssignServiceToUser,
        parameters);

      return req.pipe(response => {
        return response;
      });
    }
  }
  
}
