import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertmsgService {

  constructor() { }

  public alertMsg = '';

  setAlertMsg(alertMsg) {
    this.alertMsg = alertMsg;
    console.log(alertMsg);
  }
  getAlertMsg() {
    return this.alertMsg;
  }

  showAlert() {

  document.append(
  `<button id="openAlertModalButton2" data-toggle="modal"
  data-target="#alertModal2">alert</button>
 <div id="alertModal2" class="modal fade status-modal" role="dialog">
     <div class="modal-dialog">
           <div class="modal-content">
               <div class="modal-body text-center">
                   <img class="my-3" src="../../../assets/images/alert.png">
                 <h3>
                   ${ this.getAlertMsg() }
                   <!-- Are you sure  you want to cancel this order? -->

                 </h3>
             <div class="modal-footer py-4 justify-content-center border-0">
              <button type="reset" class="btn modal-btn w-80"   data-dismiss="modal">Ok</button>

    </div>
            </div>
           </div>
         </div>
       </div> `);
  document.getElementById('openAlertModalButton2').click();
  }
}
