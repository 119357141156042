import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { QApiReferenceService } from './q-api-reference.service';
import { QHttpHelperService } from './q-http-helper.service';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(public http: HttpClient, private _apiReference: QApiReferenceService,
              private _http: QHttpHelperService) {
  }

  callTicketToServe(sessionsId): Observable<any> {
      // const OnUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
  console.log('sessionsId: ' + sessionsId );
  // console.log('ticketId: ' + ticketId );
  if (sessionsId != null ) {

  const params = new HttpParams().set('sessionsId', sessionsId);

  const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForCallTicket , params);
  return req.pipe(response => {
   console.log('res' + JSON.stringify(response));
   return response; });
         }
       }



endTicketOrNoShow(ticketId , endOrNoShow): Observable<any> {

    if (ticketId != null ) {

    const params = new HttpParams().set('ticketId', ticketId).set('endOrNoShow', endOrNoShow);

    const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForEndOrNoShow , params);
  
    return req.pipe(response => {
     console.log('res' + JSON.stringify(response));
     return response; });
           }
         }



recallTicket(sessionsId, ticketId): Observable<any> {
if (sessionsId != null ) {
const params = new HttpParams().set('sessionsId', sessionsId).set('ticketId', ticketId);

const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForRecall , params);
// const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);

return req.pipe(response => {
 console.log('res' + JSON.stringify(response));
 return response; });
       }
     }

ticketDashboard(storeId) {
  if (storeId != null ) {
    const params = new HttpParams().set('storeId', storeId);
    const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForTicketDashboard , params);
    // const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForStartSession , params);

    return req.pipe(response => {
     console.log('res' + JSON.stringify(response));
     return response; });
           }
     }

     unReserveItemByUser( referenceId , userId)
     {
      const params = {referenceId: referenceId, userId:userId};
      const req = this._http.Post(environment.baseUrl + this._apiReference.baseURLForUnReserveItemByUser , params);
      return req.pipe(response => {
      //  console.log('res' + JSON.stringify(response));
       return response; });

     }

  reScheduleTicket( ticketId ,newTime, userId)
  {  
    const params = new HttpParams().set('ticketId', ticketId).set('newTime', newTime).set('userId', userId);

   const req = this._http.Get(environment.baseUrl + this._apiReference.baseURLForReScheduleTicket , params);
   return req.pipe(response => {
    return response; });

  }


}
