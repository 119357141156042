import { Stores } from './stores';

export class TicketData {
id: string;
mobileNumber: string;
sessionId: string;
queueId: string;
storeId: Stores;
serviceId: string;
customerName: string;
 source: string;
customerType: string;
 actualWaitingTime: string;
 expectedWaitingTime: string;
duration: string;
startServingTime: string;
endServingTime: string;
createdDate: string;
 modifiedDate: string;
 numberOfServices: string;
status: string;
ticketNumber: string;
ticketOrder: string;
hasReservation: string;
errorStatus: string;
statusCode: number;
message: string;
storeCode :string;
}
