import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { QApiReferenceService } from 'src/app/services/q-api-reference.service';
import { QHttpHelperService } from 'src/app/services/q-http-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleServService {

  constructor(public httpC: HttpClient, private apiReference: QApiReferenceService,
              private http: QHttpHelperService) { }

  getAllRoles(): Observable<any> {

    const req =  this.http.Get(environment.baseUrl + this.apiReference.baseURLForGetAllRoles);
    // alert(JSON.stringify(req));
    return req.pipe(response => {
      // alert('1: ' + JSON.stringify(response));
      return response;
    });

  }
  addRole(createdBy , roleName , description, fullStoreAccess, branchRole): Observable<any> {
  // const params = new HttpParams().set('roleName', roleName)
  //   .set('createdBy', createdBy).set('description' , description).set('fullStoreAccess', fullStoreAccess)
  //   .set('branchRole', branchRole);
  const role = {roleName: roleName, createdBy: createdBy, description: description, fullStoreAccess: fullStoreAccess, 
    branchRole: branchRole};
  // console.log(JSON.stringify(role));
  const req =  this.http.Post(environment.baseUrl + this.apiReference.baseURLForCreateRoles,
    JSON.stringify(role));

    // alert(JSON.stringify(req));
  return req.pipe(response => {
      // alert('1: ' + JSON.stringify(response));
      return response;
    });

  }

editRole( roleId , ModifiedBy , Description , RoleName): Observable<any> {

    const role = {id : roleId, description: Description , modifiedBy : ModifiedBy, roleName: RoleName};
    // console.log(JSON.stringify(role));
    const req =  this.http.Post(environment.baseUrl + this.apiReference.baseURLForEditRoles,
    JSON.stringify(role));

    // alert(JSON.stringify(req));
    return req.pipe(response => {
      // alert('1: ' + JSON.stringify(response));
      return response;
    });

  }

}
