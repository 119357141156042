export class Role {
  id: number;
  roleName: string;
  description: string;
  roleCode: string;
  createdDate: string;
  createdBy: string;
  status: string;
  modifiedBy: string;
  modifiedDate: string;
  systemId: string;
  fullStoreAccess: boolean;
  branchRole: boolean;

}
